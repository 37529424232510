<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
.remove-button, .add-button {
  font-size: 1.5em;
  background-color: #ff5c5c; /* Red color for remove button */
  color: #fff; /* White text color for buttons */
  border: none;
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0.2em;
  border-radius: 5px;
}
.add-button {
  background-color: #5cb85c; /* Green color for add button */
}
.input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
label {
  margin-right: 5px;
}
.set-readonly {
  background-color: #f8f8f8;
  color: #555;
  pointer-events: none;
}
</style>
<template>
    <vx-card :title="(this.id ? 'Detail' : 'Create') + ' Purchase Discount - FORM'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Discount Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.discount_code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Discount Name</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input type="text" v-model="data.discount_name" class="w-full" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Valid From</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <datepicker name="date" :inline="false"
                    v-model="data.valid_from" placeholder="Select Date"
                    :cleared="() => {
                        data.valid_from = null
                    }">
                </datepicker>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Valid To</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <datepicker name="date" :inline="false"
                    v-model="data.valid_to" placeholder="Select Date"
                    :cleared="() => {
                        data.valid_to = null
                    }">
                </datepicker>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%"> 
            <div class="vx-col sm:w-1/3 w-full flex items-center"> 
                <span>Created By</span> 
            </div> 
            <div class="vx-col sm:w-2/3 w-full"> 
                <div class="vx-row"> 
                    <div class="vx-col sm:w-5/12 w-full">                         
                        <vs-input :value="data.created_by" :readonly="true" class="w-full"></vs-input> 
                    </div> 
                    <div class="vx-col sm:w-2/12 w-full flex items-center text-center"> 
                        <span>Created Date</span> 
                    </div> 
                    <div class="vx-col sm:w-5/12 w-full"> 
                        <vs-input :value="formatDate(this.data.created_date)" :readonly="true" class="w-full"></vs-input> 
                    </div>                     
                </div> 
            </div>             
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Currency Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <CustomMSelect :value="data.currency_code" base-url="/api/wms/v1/master/currencies" label="code"
                track-by="code" @selected="(v) => {
                    this.data.currency_code = v;
                }"></CustomMSelect>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <vs-textarea :readonly="statusReadonly" v-model="data.note" width="100%" :class="statusReadonly ? 'bg-readonly' : ''"/>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="this.data.fileUrl != ''">
            <div class="vx-col sm:w-1/3 w-full flex items-center">

            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                                <!-- <vs-button size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                    title="Delete" /> -->
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Applicable to:</span>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <CustomMSelect :value="data.selectedSupplier" base-url="/api/wms/v1/master/suppliers" label="name"
                track-by="id" @selected="(v) => {
                    this.data.selectedSupplier = v;
                }"></CustomMSelect>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <CustomMSelect :value="wh[0].selectedWarehouse" multiple base-url="/api/wms/v1/master/warehouses" label="name"
                track-by="id" @selected="(v) => {
                    this.wh[0].selectedWarehouse = v;
                }"></CustomMSelect>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="5%">SOURCE</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(source, index) in sources" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px" :class="{ 'set-readonly': data.sd_id !== null }">
                            <v-select
                                v-model="source.sourceModel"
                                :options="source.sourceOptions"
                                @input="updateSource(source, index)"
                                placeholder="All SKU, SKU, SKU Category"
                            />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <button @click="removeItem(index)" v-if="index !== 0 && data.sd_id === null" class="remove-button">&#45;</button>
                            <button @click="addItem(index)" v-else-if="data.sd_id === null" class="add-button">&#43;</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="5%">SKU/SKU Category</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(sourceSku, index) in sourceSkus" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px" :class="{ 'set-readonly': data.sd_id !== null }">
                            <template v-if="sourceSku.selectedSourceModel === 'SKU'">
                                <CustomMSelect :value="sourceSku.itemId"
                                :base-url="`/api/wms/v1/master/items`"
                                label="name" track-by="id" 
                                @selected="(v) => {
                                    sourceSku.itemId = v;
                                    sourceSku.idItems = v.id}"></CustomMSelect>
                            </template>
                            
                            <template v-if="sourceSku.selectedSourceModel === 'SKU Category'">
                                <CustomMSelect
                                :value="sourceSku.itemId"
                                :base-url="`/api/wms/v1/master/item-category`"
                                label="name" track-by="name"
                                @selected="(v) => {
                                    sourceSku.itemId = v;
                                    sourceSku.idItems = v.id}"
                                ></CustomMSelect>
                            </template>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <button @click="removeItem1(index)" v-if="index !== 0 && data.sd_id === null" class="remove-button">&#45;</button>
                            <button @click="addItem1(index)" v-else-if="data.sd_id === null" class="add-button">&#43;</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>DISCOUNT CONDITION</span>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Discount Model</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <v-select
                    v-model="condition.discountModel"
                    :options="condition.discountOptions"
                    placeholder="Select Discount Model"
                />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Calculation Model</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <v-select
                    v-model="condition.calculationModel"
                    :options="condition.calculationOptions"
                    placeholder="Select Discount Model"
                />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span># of Level</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full" :class="{ 'set-readonly': data.sd_id !== null }">
                <vs-input v-model="condition.level" class="w-full" @input="updateTables"></vs-input>
            </div>
        </div><br>
        
        <div class="vx-row mb-6" v-for="(table, tableIndex) in tables" :key="tableIndex">
            <div class="vx-col w-full mb-6">
                <div class="input-container" :class="{ 'set-readonly': data.sd_id !== null }">
                    <label for="input1">DISCOUNT TYPE</label>
                    <v-select
                        v-model="table.discountTypeModel"
                        :options="table.discountTypeOptions"
                        placeholder="Select Discount Model"
                    />

                    <label for="input2">BASE CALCULATION</label>
                    <v-select
                        v-model="table.baseModel"
                        :options="table.baseOptions"
                        placeholder="Select Discount Model"
                    />

                    <label for="input3">MAP</label>
                    <input type="checkbox" :checked="table.map === 1" v-model="table.map" />
                    <input type="hidden" v-model="table.levelHeader"/>
                </div>
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="5%">From</th>
                            <th width="5%">To</th>
                            <th width="5%">HU</th>
                            <th width="5%">% Discount</th>
                            <th width="5%">Unit</th>
                            <th width="5%">Amount</th>
                            <th width="3%">Multiply</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, rowIndex) in table.items" :key="rowIndex" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="number" v-model="item.from" class="w-full" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="number" v-model="item.to" class="w-full" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px" :class="{ 'set-readonly': data.sd_id !== null }">
                            <CustomMSelect :value="item.hu"
                                :base-url="`/api/wms/v1/master/units`"
                                label="name" track-by="name" 
                                @selected="(v) => {
                                    item.hu = v;
                                    item.unitId = v.id}">
                            </CustomMSelect>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="number" v-model="item.discount" class="w-full" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="text" v-model="item.unit" class="w-full" :disabled="isDiscountTypePercentage(tableIndex)" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="number" v-model="item.amount" class="w-full" :disabled="isDiscountTypePercentage(tableIndex)" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                          <vs-input type="checkbox" :checked="item.multiply === 1" v-model="item.multiply" class="w-full" :disabled="isDiscountTypePercentage(tableIndex)" :class="{ 'set-readonly': data.sd_id !== null }"></vs-input>
                          <input type="hidden" v-model="item.step"/>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <button @click="removeItem2(tableIndex, rowIndex)" v-if="rowIndex !== 0 && data.sd_id === null" class="remove-button">&#45;</button>
                            <button @click="addItem2(tableIndex, rowIndex)" v-else-if="data.sd_id === null" class="add-button">&#43;</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button v-if="data.sd_id == null" class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" @click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>

import _ from "lodash";
import Warehouse from "@/components/Warehouse.vue";
import Date from "@/components/Date.vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Supplier from "@/components/Supplier.vue";
import CustomMSelect from "@/components/CustomMultiSelect.vue";

import vSelect from "vue-select";
export default {
    components: {
        Warehouse,
        Date,
        Datepicker,
        vSelect,
        Supplier,
        CustomMSelect,
    },
    data() {
        return {
            data: {
                // selectedWarehouse: null,
                selectedSupplier: null,
                discount_code: null,
                discount_name: null,
                currency_code: null,
                valid_from: null,
                valid_to: null,
                created_by: null,
                created_date: null,
                note: null,
                pd_id: null,
                sd_id: null,
                sdw_id: null,
                file: null,
                status: null,
                fileUrl: '',
            },
            condition: {
                discountModel: '%',
                discountOptions: [
                    { label: '%', value: '%' },
                    { label: 'Amount', value: 'Amount' },
                ],
                calculationModel: 'Final',
                calculationOptions: [
                    { label: 'Step', value: 'Step' },
                    { label: 'Final', value: 'Final' },
                ],
                level: 1,
                conditionId: 0,
            },
            wh: [
                {
                    selectedWarehouse: [],
                }
            ],
            sources: [
                {
                    sourceModel: 'All SKU',
                    sourceOptions: [
                        { label: 'All SKU', value: 'All SKU' },
                        { label: 'SKU', value: 'SKU' },
                        { label: 'SKU Category', value: 'SKU Category' },
                    ],
                }
            ],
            sourceSkus: [],
            tables: [],
            statusReadonly: false,
            statusTab: null,
        }
    },
    mounted() {
        this.updateTables();
        // var now = moment()
        // this.data.receive_date = now
        // now.add(1, 'days')

        this.id = this.$route.params.id;
        this.statusTab = this.$route.params.tab;
        if (this.id) { // sto id
            this.getData()
        }
        console.log('sd_id value:', this.data.sd_id);
    },
    methods: {
        addItem(index) {
            const newItem = {
                sourceModel: 'All SKU',
                sourceOptions: [
                    { label: 'All SKU', value: 'All SKU' },
                    { label: 'SKU', value: 'SKU' },
                    { label: 'SKU Category', value: 'SKU Category' },
                ],
            };
            this.sources.splice(index + 1, 0, newItem);
        },
        removeItem(index) {
            if (this.sources.length > 1) {
                this.sources.splice(index, 1);
            }
        },
        addItem1(index1) {
            // Add a new row after the specified index
            const prevSelectedSourceModel = this.sourceSkus[index1].selectedSourceModel;
            const itemSource = {
                itemId: '',
                idItems: '',
                selectedSourceModel: prevSelectedSourceModel,
            };
            this.sourceSkus.splice(index1 + 1, 0, itemSource);
        },
        removeItem1(index1) {
            // Remove the row at the specified index
            if (this.sourceSkus.length > 1) {
                this.sourceSkus.splice(index1, 1);
            }
        },
        // addItem2(tableIndex, rowIndex) {
        // // Add a new row to the specified table
        //     this.$set(this.tables, tableIndex, {
        //         items: [
        //         ...this.tables[tableIndex].items,
        //         {
        //             from: 0,
        //             to: 0,
        //             hu: '',
        //             discount: 0,
        //             unit: '',
        //             unitId: '',
        //             amount: 0,
        //             multiply: 0,
        //             discountTypeModel: this.tables[tableIndex].discountTypeModel,
        //             baseModel: this.tables[tableIndex].baseModel,
        //             map: this.tables[tableIndex].map,
        //         },
        //         ],
        //     });
        // },
        addItem2(tableIndex, rowIndex) {
            // Add a new row to the specified table
            const isPercentage = this.isDiscountTypePercentage(tableIndex);

            // Create a copy of the items array and push the new item to it
            const newItems = [...this.tables[tableIndex].items, {
            from: 0,
            to: 0,
            hu: '',
            discount: 0,
            unit: '',
            unitId: '',
            amount: 0,
            multiply: 0,
            discountTypeModel: this.tables[tableIndex].discountTypeModel,
            baseModel: this.tables[tableIndex].baseModel,
            map: this.tables[tableIndex].map,
            isPercentage: isPercentage,
            }];

            // Use Vue.set to update the items array in a reactive way
            this.$set(this.tables, tableIndex, { ...this.tables[tableIndex], items: newItems });
        },
        removeItem2(tableIndex, rowIndex) {
        // Remove the row at the specified index from the specified table
            if (this.tables[tableIndex].items.length > 1) {
                this.$set(
                this.tables,
                tableIndex,
                Object.assign({}, this.tables[tableIndex], {
                    items: this.tables[tableIndex].items.filter((item, index) => index !== rowIndex),
                })
                );
            }
        },

        // Method to update the number of tables based on the level input
        updateTables() {
            const numberOfTables = parseInt(this.condition.level, 10) || 0;
            this.tables = new Array(numberOfTables).fill(null).map(() => this.generateTable());
        },
        // Helper method to generate the initial structure of a table
        generateTable() {
            return {
                discountTypeModel: '%',
                discountTypeOptions: [
                    { label: '%', value: '%' },
                    { label: 'Amount', value: 'Amount' },
                ],
                baseModel: 'Original',
                baseOptions: [
                    { label: 'Original', value: 'Original' },
                    { label: 'Discounted', value: 'Discounted' },
                ],
                map: '',
                levelHeader: '',
                items: [
                    {
                        from: 0,
                        to: 0,
                        hu: '',
                        discount: 0,
                        unit: '',
                        unitId: '',
                        amount: 0,
                        multiply: '',
                        discountTypeModel: this.tables.discountTypeModel,
                        baseModel: this.tables.baseModel,
                        map: this.tables.map,
                    },
                ],
            };
        },
        updateSource(source, index){
            console.log(`Selected value at index ${index}: ${source.sourceModel.value}`);
            // this.selectedSourceModel = source.sourceModel.value;
                this.$set(this.sourceSkus, index, {
                ...this.sourceSkus[index],
                selectedSourceModel: source.sourceModel.value,
            });
        },
        // isDiscountTypePercentage(discountType) {
        //     return discountType === '%';
        // },
        isDiscountTypePercentage(tableIndex) {
            // Assuming discountType is an array
            if (this.tables[tableIndex] && this.tables[tableIndex].discountTypeModel) {
                return this.tables[tableIndex].discountTypeModel === '%';
            }

        // Default to false if discountType is not an array or doesn't have a value for the given tableIndex
        return false;
        },
        getData() {
            console.log('tes',this.$store.state.master.purchaseDiscount.baseUrlPath  + "/" + this.id)
            this.$vs.loading();
            this.$http
                .get(this.$store.state.master.purchaseDiscount.baseUrlPath + "/" + this.id)
                .then((resp) => {
                    console.log('respon',resp)
                    if (resp.code == 200) {
                        var now = moment().toDate();
                        this.data.sd_id = resp.data.sd_id
                        this.data.sdw_id = resp.data.sdw_id
                        this.data.discount_code = resp.data.sd_code
                        this.data.discount_name = resp.data.sd_name
                        this.data.valid_from = resp.data.valid_from == null ? now : resp.data.valid_from
                        this.data.valid_to = resp.data.valid_to == null ? now : resp.data.valid_to
                        this.data.created_by = resp.data.created_by
                        this.data.created_date = resp.data.created_at
                        this.data.currency_code = {
                            code : resp.data.currency_code
                        }
                        this.data.selectedSupplier = {
                            id : resp.data.supplier_id,
                            name : resp.data.supplier_name,
                        }
                        // this.data.selectedWarehouse = {
                        //     id : resp.data.warehouse_id,
                        //     name : resp.data.wh_name,
                        // }
                        this.data.note = resp.data.notes
                        this.data.status = resp.data.status
                        this.condition.discountModel = resp.data.discount_model
                        this.condition.calculationModel = resp.data.calculation_model
                        this.condition.level = resp.data.level
                        this.condition.conditionId = resp.data.sdc_id
                        // this.updateTables();
                        this.fileUrl = resp.data.file_url
                        
                        console.log('warehouse', resp.data.warehouse)
                        this.wh[0].selectedWarehouse = resp.data.warehouse.map(el => ({
                            id: el.warehouse_id,
                            name: el.name,
                            sdw_id: el.id,
                        }));

                        this.tables.splice(0, this.tables.length);

                        var jmllevel = []
                        resp.data.lines.forEach((el, index) => {
                            jmllevel.push(el);
                        });
                        var groupedData = jmllevel.reduce((result, currentItem) => {
                            // Jika belum ada properti dengan step yang sama, buat array kosong
                            if (!result[currentItem.step]) {
                                result[currentItem.step] = [];
                            }
                            // Tambahkan item ke dalam array yang sesuai dengan step-nya
                            result[currentItem.step].push(currentItem);
                            return result;
                        }, {});

                        console.log('Grouped Data', groupedData);
                        console.log('lines',resp.data.lines)
                        this.tables = [];
                        for (const Step in groupedData) {
                            if (Object.prototype.hasOwnProperty.call(groupedData, Step)) {
                                const il = groupedData[Step];
                                const newDiscount = {
                                    discountTypeModel: il[0].discount_type,
                                    discountTypeOptions: [
                                        { label: '%', value: '%' },
                                        { label: 'Amount', value: 'Amount' },
                                    ],
                                    baseModel: il[0].base_calculation,
                                    baseOptions: [
                                        { label: 'Original', value: 'Original' },
                                        { label: 'Discounted', value: 'Discounted' },
                                    ],
                                    map: il[0].map,
                                    levelHeader: il[0].step,
                                    items : [],
                                };
                                resp.data.lines.forEach((el, index) => {
                                    if(il[0].step == el.step) {
                                        newDiscount.items.push({
                                            from: el.from_qty,
                                            to: el.to_qty,
                                            hu: {
                                                id: el.unit_id,
                                                name: el.hu,
                                            },
                                            unitId: el.unit_id,
                                            discount: el.discount,
                                            unit: el.unit,
                                            amount: el.amount,
                                            multiply: el.multiply,
                                            discountTypeModel: el.discount_type,
                                            baseModel: el.base_calculation,
                                            map: el.map,
                                            lineId: el.sd_line_id,
                                        });
                                    }
                                });                           
                                this.tables.push(newDiscount);
                            }
                        }

                        resp.data.groupsource.forEach((el, index) => {
                            const newItem = {
                            sourceModel: el.source,
                            sourceOptions: [
                                { label: 'All SKU', value: 'All SKU' },
                                { label: 'SKU', value: 'SKU' },
                                { label: 'SKU Category', value: 'SKU Category' },
                            ],
                          }
                          this.sources.push(newItem)
                          this.$set(this.sourceSkus, index, {
                                ...this.sourceSkus[index],
                                selectedSourceModel: el.source,
                            });
                        });

                        console.log('source', resp.data.source);
                        this.sourceSkus.splice(0, this.sourceSkus.length);
                        resp.data.source.forEach((el, index) => {
                            var dataitem = null
                            if(el.source == "SKU"){
                                dataitem = el.item_id
                            }else {
                                dataitem = el.item_category_id
                            }
                            const itemSource = {
                                itemId: {
                                    id : dataitem,
                                    name : el.item_name
                                },
                                idItems: dataitem,
                                sourceId: el.sds_id,
                                selectedSourceModel: el.source,
                            };
                          this.sourceSkus.push(itemSource)
                        });
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            // this.$router.push({
            //     name: this.$store.state.master.purchaseDiscount.baseRouterName,
            // });
            this.$router.push({
                name: "master.purchase-discount-approval",
                params: {status: this.statusTab}
            });
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit() {
            this.data.valid_from = moment(this.data.valid_from).format('YYYY-MM-DD')
            this.data.valid_to = moment(this.data.valid_to).format('YYYY-MM-DD')
            console.log('valid_from',this.data.valid_from)
            this.data.currency_code = this.data.currency_code.code
            this.data.selectedSupplier = this.data.selectedSupplier.id
            // this.data.selectedWarehouse = this.data.selectedWarehouser.id
            this.condition.level = parseFloat(this.condition.level);
            this.condition.discountOptions = this.condition.discountOptions.value
            this.condition.calculationOptions = this.condition.calculationOptions.value
            this.tables.forEach((table, tableIndex) => {
                table.items.forEach((item, rowIndex) => {
                    item.hu = item.hu.name
                    item.from = parseFloat(item.from);
                    item.to = parseFloat(item.to);
                    item.discount = parseFloat(item.discount);
                    item.amount = parseFloat(item.amount);
                    item.multiply = item.multiply ? 1 : 0;
                    item.map = item.map ? 1 : 0;
                    item.step = tableIndex
                    // console.log('tabel',table)
                    item.discountTypeModel = table.discountTypeModel
                    item.baseModel = table.baseModel
                });
            });

            this.sourceSkus.forEach((source) => {
                source.itemId = source.itemId.id
            })
            console.log('warehouse',JSON.stringify(this.wh))
            // this.wh.selectedWarehouse = this.wh.map((warehouse) => warehouse.selectedWarehouse.id);
            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("data", JSON.stringify(this.data))
            formData.append("condition", JSON.stringify(this.condition))
            formData.append("lines", JSON.stringify(this.tables))
            formData.append("source", JSON.stringify(this.sourceSkus))
            formData.append("warehouse", JSON.stringify(this.wh))
            console.log('lines',JSON.stringify(this.tables))
            console.log('source',JSON.stringify(this.sourceSkus))
            console.log('data',JSON.stringify(this.data))
            console.log('condition',JSON.stringify(this.condition))
            this.$vs.loading();
            let http = null
            if (this.data.sd_id != null) {
                http = this.$http.put(this.$store.state.master.purchaseDiscount.baseUrlPath + "/" + this.data.sd_id, formData)
            } else {
                http = this.$http.post(this.$store.state.master.purchaseDiscount.baseUrlPath, formData)
            }

            http.then((resp) => {
                console.log(resp)
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });

                    this.$vs.loading.close();
                    this.draw++;
                    this.handleBack()
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });

        },
        openTab(uri) {
            window.open(uri)
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
    },
    watch: {
    }
}
</script>